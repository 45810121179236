import { MenuItem } from 'types';
import { itemTransformer, mealTransformer } from 'helpers/menuTransformers';
import {
  burgersAndWraps,
  combinationKebabs,
  drinks,
  fish,
  friedChicken,
  kebabs,
  mealsForOne,
  mealsForOnePlus,
  startersAndExtras,
} from 'helpers/menu';
import MealForOne from '../images/background/mealsForOne.jpg';
import MealForOnePlus from '../images/background/mealsForOnePlus.jpg';

import Doner from '../images/background/doner.jpg';
import Shish from '../images/background/shish.jpg';
import Fish from '../images/background/fish.jpg';
import Wraps from '../images/background/wraps.jpg';
import FriedChicken from '../images/background/friedChicken.jpg';
import Starters from '../images/background/starters.jpg';
import Drinks from '../images/background/drinks.jpg';

import mealsForOneIcon from '../images/menu-icons/mealsForOne.svg';
import mealsForOnePlusIcon from '../images/menu-icons/mealsForOnePlus.svg';
import donerIcon from '../images/menu-icons/doner.svg';
import shishIcon from '../images/menu-icons/shish.svg';
import fishIcon from '../images/menu-icons/fish.svg';
import wrapIcon from '../images/menu-icons/wrap.svg';
import friedChickenIcon from '../images/menu-icons/friedChicken.svg';
import startersIcon from '../images/menu-icons/starters.svg';
import drinksIcon from '../images/menu-icons/drinks.svg';

type MenuSection = {
  icon: string;
  containerId: string;
  heading: string;
  items: MenuItem[];
  background: string;
  description?: string;
};

export const menuSections: MenuSection[] = [
  {
    icon: mealsForOneIcon,
    containerId: 'meal-for-one',
    heading: 'Meals for One',
    items: mealTransformer(mealsForOne),
    background: MealForOne,
  },
  {
    icon: mealsForOnePlusIcon,
    containerId: 'meal-for-two-or-more',
    heading: 'Meals for 1+',
    items: mealTransformer(mealsForOnePlus),
    background: MealForOnePlus,
  },
  {
    icon: donerIcon,
    containerId: 'kebabs',
    heading: 'Kebabs',
    items: itemTransformer(kebabs),
    background: Doner,
    description:
      'All our kebabs are cooked on charcoal grill & served with salad, pita bread & your choice of sauce (chilli/garlic). Kebabs can be served in a wrap for an additional £0.50.',
  },
  {
    icon: shishIcon,
    containerId: 'combination-kebabs',
    heading: 'Combination Kebabs',
    items: itemTransformer(combinationKebabs),
    background: Shish,
  },
  {
    icon: fishIcon,
    containerId: 'fish',
    heading: 'Fish',
    items: itemTransformer(fish),
    background: Fish,
  },
  {
    icon: wrapIcon,
    containerId: 'burgers-and-wraps',
    heading: 'Burgers & Wraps',
    items: itemTransformer(burgersAndWraps),
    background: Wraps,
  },
  {
    icon: friedChickenIcon,
    containerId: 'fried-chicken',
    heading: 'Fried Chicken',
    items: itemTransformer(friedChicken),
    background: FriedChicken,
  },
  {
    icon: startersIcon,
    containerId: 'starters-and-extras',
    heading: 'Starters & Extras',
    items: itemTransformer(startersAndExtras),
    background: Starters,
  },
  {
    icon: drinksIcon,
    containerId: 'drinks',
    heading: 'Drinks',
    items: itemTransformer(drinks),
    background: Drinks,
  },
];
