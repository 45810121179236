import { menuSections } from 'helpers/menuSections';
import React from 'react';
import tw from 'twin.macro';

import logo from '../../images/yummys.png';
import { ReactComponent as MapPinIcon } from 'feather-icons/dist/icons/map-pin.svg';
import { ReactComponent as PhoneIcon } from 'feather-icons/dist/icons/phone.svg';
import { ReactComponent as ClockIcon } from 'feather-icons/dist/icons/clock.svg';
import { ReactComponent as TruckIcon } from 'feather-icons/dist/icons/truck.svg';
import { ReactComponent as MenuIcon } from 'feather-icons/dist/icons/book-open.svg';

const Container = tw.div`relative bg-gray-900 text-gray-100 -mx-8 -mb-8 px-16`;
const Content = tw.div`max-w-screen-xl mx-auto pt-16 pb-8`;
const FiveColumns = tw.div`flex flex-wrap justify-between`;
const Column = tw.div`w-1/2 md:w-1/5 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`;
const ColumnHeading = tw.h5`font-bold uppercase text-center`;

const LinkList = tw.ul`text-sm font-medium`;
const LinkListItem = tw.li`text-center`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;

const LogoColumn = tw.div`w-full md:w-1/5 mb-8 md:mb-0 text-sm sm:text-base text-center`;
const FooterImage = tw.img`pt-2 h-40 inline`;

const MenuColumn = tw.div`w-full md:w-1/5 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`;

const Divider = tw.div`my-8 border-b-2 border-gray-800`;
const VerticalSpacer = tw.div`mt-6 w-full`;

const MapPin = tw(MapPinIcon)`inline mr-2`;
const Phone = tw(PhoneIcon)`inline mr-2`;
const Clock = tw(ClockIcon)`inline mr-2`;
const Truck = tw(TruckIcon)`inline mr-2`;
const Menu = tw(MenuIcon)`inline mr-2`;

export default () => {
  return (
    <Container>
      <Content>
        <FiveColumns>
          <LogoColumn>
            <FooterImage src={logo} alt={`Yummy's logo`} />
          </LogoColumn>
          <Column>
            <ColumnHeading>
              <MapPin />
              Address
            </ColumnHeading>
            <LinkList>
              <LinkListItem>4 Cambridge Parade</LinkListItem>
              <LinkListItem>Great Cambridge Road</LinkListItem>
              <LinkListItem>Enfield, EN1 4JU</LinkListItem>
            </LinkList>
            <VerticalSpacer />
            <ColumnHeading>
              <Phone />
              Phone
            </ColumnHeading>
            <LinkList>
              <LinkListItem>020 8363 8088</LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>
              <Clock />
              Opening Times
            </ColumnHeading>
            <LinkList>
              <LinkListItem>11AM - 11PM</LinkListItem>
              <LinkListItem>7 days a week</LinkListItem>
            </LinkList>
            <VerticalSpacer />
            <ColumnHeading>
              <Truck />
              Delivery Times
            </ColumnHeading>
            <LinkList>
              <LinkListItem>12PM - 10PM</LinkListItem>
              <LinkListItem>
                <small>(except 3PM - 4PM on school days)</small>
              </LinkListItem>
            </LinkList>
          </Column>
          <MenuColumn>
            <ColumnHeading>
              <Menu />
              Menu
            </ColumnHeading>
            <LinkList>
              {menuSections.map((menuSection, i) => (
                <LinkListItem key={i}>
                  <Link href={`#${menuSection.containerId}`}>
                    {menuSection.heading}
                  </Link>
                </LinkListItem>
              ))}
            </LinkList>
          </MenuColumn>
        </FiveColumns>
        <Divider />
      </Content>
    </Container>
  );
};
