export type MealItem = {
  unit?: 'piece' | 'can' | 'bottle' | 'box';
  quantity?: number;
  size?: 'Regular' | 'Large' | 'Small';
  item: string;
};

export type MealGroup = {
  number?: number;
  name: string;
  meals: Meal[];
};

export type Meal = {
  number?: number;
  name?: string;
  price: number;
  subItems: MealItem[];
};

export type MenuItems = (Meal | MealGroup)[];

export const isMeal = (x: any): x is Meal => {
  return x.hasOwnProperty('subItems');
};

export type FoodVariant = {
  variant?: string; // 'Medium' | 'Large' | 'With Chips';
  price: number;
};

export type FoodItem = {
  number?: number;
  name: string;
  description?: string;
  variants: FoodVariant[];
};

type VariantPrice = {
  variant: string,
  price: string,
};

export type MenuItem = {
  name: string;
  description: string;
  price: string | VariantPrice[];
};

export type MenuSection = {
  containerId: string;
  heading: string;
  subheading?: string;
  description?: string;
  items: MenuItem[];
  background: string;
};
