import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from 'components/misc/Headings.js';
import { SectionDescription } from 'components/misc/Typography.js';
import { MenuSection } from 'types';

const Container = styled.div`
  ${tw`relative bg-primary-900 -mx-8 px-8 text-gray-100 bg-cover bg-center`}
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;

const Variant = tw.span`font-medium`;
const Price = tw(SubheadingBase)`text-gray-100 text-center`;
const Heading = tw(SectionHeading)`w-full text-center z-20`;
const Description = tw(
  SectionDescription
)`w-full text-center text-gray-300 z-20`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs z-20`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center h-full mx-4 px-2 py-4`}
  .imageContainer {
    ${tw`bg-gray-100 text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`mt-6 w-full`}
  }

  .title {
    ${tw`tracking-wider font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-400 leading-snug text-center`}
  }
`;

export default ({
  containerId,
  heading,
  description = '',
  items,
  background,
}: MenuSection) => {
  return (
    <Container
      style={{ backgroundImage: `url(${background})` }}
      id={containerId}
    >
      <OpacityOverlay />
      <ThreeColumnContainer>
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {items.map((item, i) => (
          <Column key={i}>
            <Card>
              <span className="textContainer">
                <span className="title">{item.name}</span>
                {item.description && (
                  <p className="description">{item.description}</p>
                )}
                {Array.isArray(item.price) &&
                  item.price.map((price) => (
                    <Price>
                      <Variant>{`${price.variant}  `}</Variant>
                      {price.price}
                    </Price>
                  ))}
                {!Array.isArray(item.price) && <Price>{item.price}</Price>}
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
