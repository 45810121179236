import 'tailwindcss/dist/base.css';
import 'styles/globalStyles.css';

import React from 'react';
import AnimationRevealPage from 'helpers/AnimationRevealPage';
import Hero from 'components/hero/Homepage';
import MenuSection from 'components/features/MenuSection';
import MenuDividers from 'components/features/MenuDividers';
import Footer from 'components/footers/FiveColumnDark';
import { menuSections } from 'helpers/menuSections';

export default function App() {
  return (
    <AnimationRevealPage disabled>
      <Hero />
      {menuSections.map((menuSection, i) => (
        <>
        <MenuDividers />
        <MenuSection key={i} {...menuSection} />
        </>
      ))}
      <Footer />
    </AnimationRevealPage>
  );
}
