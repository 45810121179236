import { FoodItem, MenuItems } from 'types';

export const mealsForOne: MenuItems = [
  {
    number: 1,
    name: 'Chicken Meal',
    price: 4.5,
    subItems: [
      {
        quantity: 2,
        item: 'Chicken',
        unit: 'piece',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 2,
    name: 'Combo Meal 1',
    price: 4.5,
    subItems: [
      {
        quantity: 1,
        item: 'Chicken',
        unit: 'piece',
      },
      {
        quantity: 3,
        item: 'Spicy Wings',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 3,
    name: 'Chicken Mega',
    price: 5.5,
    subItems: [
      {
        quantity: 3,
        item: 'Chicken',
        unit: 'piece',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 4,
    name: 'Combo Meal 2',
    price: 5.5,
    subItems: [
      {
        quantity: 2,
        item: 'Chicken',
        unit: 'piece',
      },
      {
        quantity: 3,
        item: 'Spicy Wings',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 5,
    name: 'Spicy Wing',
    price: 4.5,
    subItems: [
      {
        quantity: 6,
        item: 'Spicy Wings',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 6,
    name: 'Mini Chicken Sampler',
    price: 6,
    subItems: [
      {
        quantity: 1,
        unit: 'piece',
        item: 'Chicken',
      },
      {
        quantity: 3,
        item: 'Spicy Wings',
      },
      {
        quantity: 1,
        item: 'Fillet Strip',
      },
      {
        quantity: 6,
        unit: 'piece',
        item: 'Popcorn',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 7,
    name: 'Nuggets Meal',
    price: 4.5,
    subItems: [
      {
        quantity: 6,
        unit: 'piece',
        item: 'Nuggets',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 8,
    name: 'Popcorn Meal',
    price: 4.5,
    subItems: [
      {
        quantity: 12,
        unit: 'piece',
        item: 'Popcorn',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 9,
    name: 'Chicken Strips',
    price: 5.5,
    subItems: [
      {
        quantity: 5,
        unit: 'piece',
        item: 'Strips',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 10,
    name: 'BBQ Ribs & Chicken Meal',
    meals: [
      {
        price: 5.5,
        subItems: [
          {
            item: 'Lamb Ribs',
            quantity: 2,
          },
          {
            quantity: 1,
            unit: 'piece',
            item: 'Chicken',
          },
          {
            item: 'Fries',
            size: 'Regular',
          },
          {
            quantity: 1,
            unit: 'can',
            item: 'drink',
          },
        ],
      },
      {
        price: 7,
        subItems: [
          {
            item: 'Lamb Ribs',
            quantity: 3,
          },
          {
            quantity: 1,
            unit: 'piece',
            item: 'Chicken',
          },
          {
            item: 'Fries',
            size: 'Regular',
          },
          {
            quantity: 1,
            unit: 'can',
            item: 'drink',
          },
        ],
      },
      {
        price: 5.8,
        subItems: [
          {
            item: 'Lamb Rib',
            quantity: 1,
          },
          {
            quantity: 1,
            unit: 'piece',
            item: 'Chicken',
          },
          {
            quantity: 3,
            item: 'Spicy Wings',
          },
          {
            item: 'Fries',
            size: 'Regular',
          },
          {
            quantity: 1,
            unit: 'can',
            item: 'drink',
          },
        ],
      },
    ],
  },
  {
    number: 11,
    name: 'BBQ Golden Meal',
    price: 5,
    subItems: [
      {
        quantity: 2,
        item: 'BBQ Chicken',
        unit: 'piece',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 12,
    name: 'BBQ Wings',
    price: 5,
    subItems: [
      {
        quantity: 6,
        item: 'BBQ Wings',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 13,
    name: 'BBQ Wings & BBQ Chicken',
    price: 5,
    subItems: [
      {
        quantity: 3,
        item: 'BBQ Wings',
      },
      {
        quantity: 1,
        item: 'BBQ Chicken',
        unit: 'piece',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 14,
    name: 'BBQ Ribs',
    meals: [
      {
        price: 6.5,
        subItems: [
          {
            item: 'Lamb Ribs',
            quantity: 4,
          },
          {
            item: 'Fries',
            size: 'Regular',
          },
          {
            quantity: 1,
            unit: 'can',
            item: 'drink',
          },
        ],
      },
      {
        price: 7.5,
        subItems: [
          {
            item: 'Lamb Ribs',
            quantity: 6,
          },
          {
            item: 'Fries',
            size: 'Regular',
          },
          {
            quantity: 1,
            unit: 'can',
            item: 'drink',
          },
        ],
      },
    ],
  },
  {
    number: 15,
    name: 'BBQ Burger',
    price: 5,
    subItems: [
      {
        item: 'BBQ Chicken Burger',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 16,
    name: 'Chicken Burger',
    price: 4.5,
    subItems: [
      {
        item: 'Chicken Fillet Burger',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 17,
    name: 'Chicken Burger & Wings',
    price: 5.5,
    subItems: [
      {
        item: 'Chicken Fillet Burger',
      },
      {
        quantity: 3,
        item: 'Spicy Wings',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 18,
    name: 'Tower Burger',
    price: 5.3,
    subItems: [
      {
        item: 'Chicken Fillet Burger with Hash Brown & Cheese',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 19,
    name: 'Ringer Burger',
    price: 5.3,
    subItems: [
      {
        item: 'Chicken Fillet Burger with 2 Onion Rings & Cheese',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 20,
    name: 'Fish Burger',
    price: 4.8,
    subItems: [
      {
        item: 'Fish Burger',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 21,
    name: 'Veggie Burger',
    price: 4.8,
    subItems: [
      {
        item: 'Veggie Burger',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 22,
    name: 'Burger+ Meal',
    price: 7,
    subItems: [
      {
        item: 'Chicken Fillet Burger',
      },
      {
        quantity: 3,
        item: 'Spicy Wings',
      },
      {
        quantity: 1,
        item: 'Chicken',
        unit: 'piece',
      },
      {
        item: 'Coleslaw or Beans',
        size: 'Regular',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 23,
    name: 'Beef Burger',
    price: 5.5,
    subItems: [
      {
        item: '1/4 Pounder',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 24,
    name: '1/2 Pounder',
    price: 6.5,
    subItems: [
      {
        item: '1/2 Pounder',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 25,
    name: 'Double Decker',
    price: 4.5,
    subItems: [
      {
        item: 'Double Beef Burger',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 26,
    name: 'Chicken Wrap',
    price: 5,
    subItems: [
      {
        item: 'Chicken Wrap',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 27,
    name: 'BBQ Wrap',
    price: 5.3,
    subItems: [
      {
        item: 'BBQ Chicken Wrap',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    number: 28,
    name: 'Veggie Wrap',
    price: 5,
    subItems: [
      {
        item: 'Veggie Wrap',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    name: 'Boneless Meal',
    price: 6,
    subItems: [
      {
        quantity: 3,
        item: 'Fillet Strips',
      },
      {
        size: 'Small',
        item: 'Poppers',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    name: 'Sausage Meal',
    price: 4.5,
    subItems: [
      {
        item: 'Sausage',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    name: 'Cod Fish Meal',
    price: 6.5,
    subItems: [
      {
        item: 'Cod Fish',
        size: 'Large',
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    name: 'Scampi Meal',
    price: 6.3,
    subItems: [
      {
        item: 'Scampi',
        unit: 'piece',
        quantity: 8,
      },
      {
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
];

export const mealsForOnePlus: MenuItems = [
  {
    name: 'Mixed Bucket',
    price: 22,
    subItems: [
      {
        quantity: 4,
        item: 'Chicken',
        unit: 'piece',
      },
      {
        quantity: 4,
        item: 'Chicken or Beef Burgers',
      },
      {
        quantity: 6,
        item: 'Spicy Wings',
      },
      {
        quantity: 4,
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'bottle',
        item: 'drink',
      },
    ],
  },
  {
    name: 'Mixed Meal',
    price: 10,
    subItems: [
      {
        item: 'Chicken Fillet Burger',
      },
      {
        quantity: 3,
        item: 'Chicken',
        unit: 'piece',
      },
      {
        quantity: 4,
        item: 'Spicy Wings',
      },
      {
        quantity: 2,
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 2,
        unit: 'can',
        item: 'drink',
      },
    ],
  },
  {
    name: 'Family Deal',
    price: 28,
    subItems: [
      {
        quantity: 6,
        item: 'Chicken',
        unit: 'piece',
      },
      {
        quantity: 3,
        item: 'Chicken Fillet Burgers',
      },
      {
        quantity: 6,
        item: 'Spicy Wings',
      },
      {
        item: 'Lamb Ribs',
        quantity: 6,
      },
      {
        quantity: 4,
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'bottle',
        item: 'drink',
      },
    ],
  },
  {
    name: 'Boneless Deal',
    price: 19,
    subItems: [
      {
        quantity: 6,
        item: 'Fillet Strips',
      },
      {
        quantity: 2,
        item: 'Chicken Fillet Burgers',
      },
      {
        quantity: 2,
        item: 'Chicken Wraps',
      },
      {
        quantity: 4,
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'bottle',
        item: 'drink',
      },
    ],
  },
  {
    name: 'Boneless Sampler',
    price: 19,
    subItems: [
      {
        quantity: 4,
        item: 'Chicken Fillets',
        unit: 'piece',
      },
      {
        quantity: 8,
        item: 'Fillet Strips',
      },
      {
        size: 'Large',
        item: 'Poppers',
      },
      {
        quantity: 4,
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'bottle',
        item: 'drink',
      },
    ],
  },
  {
    name: 'Chicken Sampler',
    price: 19,
    subItems: [
      {
        quantity: 4,
        item: 'Chicken',
        unit: 'piece',
      },
      {
        quantity: 4,
        item: 'Fillet Strips',
      },
      {
        quantity: 8,
        item: 'Spicy Wings',
      },
      {
        size: 'Large',
        item: 'Poppers',
      },
      {
        quantity: 4,
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'bottle',
        item: 'drink',
      },
    ],
  },
  {
    name: 'BBQ Bucket',
    price: 14,
    subItems: [
      {
        quantity: 4,
        item: 'BBQ Chicken',
        unit: 'piece',
      },
      {
        quantity: 1,
        item: 'BBQ Chicken Burger',
      },
      {
        quantity: 8,
        item: 'BBQ Wings',
      },
      {
        quantity: 4,
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'bottle',
        item: 'drink',
      },
    ],
  },
  {
    name: 'Wings Bucket',
    meals: [
      {
        price: 14,
        subItems: [
          {
            quantity: 20,
            item: 'Spicy Wings',
          },
          {
            quantity: 4,
            item: 'Fries',
            size: 'Regular',
          },
          {
            quantity: 1,
            unit: 'bottle',
            item: 'drink',
          },
        ],
      },
      {
        price: 16,
        subItems: [
          {
            quantity: 30,
            item: 'Spicy Wings',
          },
          {
            quantity: 4,
            item: 'Fries',
            size: 'Regular',
          },
          {
            quantity: 1,
            unit: 'bottle',
            item: 'drink',
          },
        ],
      },
    ],
  },
  {
    name: 'Family Value Meal',
    meals: [
      {
        price: 14,
        subItems: [
          {
            quantity: 6,
            item: 'Chicken',
            unit: 'piece',
          },
          {
            quantity: 6,
            item: 'Spicy Wings',
          },
          {
            item: 'Coleslaw or Beans',
            size: 'Large',
          },
          {
            quantity: 4,
            item: 'Fries',
            size: 'Regular',
          },
          {
            quantity: 1,
            unit: 'bottle',
            item: 'drink',
          },
        ],
      },
      {
        price: 16,
        subItems: [
          {
            quantity: 8,
            item: 'Chicken',
            unit: 'piece',
          },
          {
            quantity: 8,
            item: 'Spicy Wings',
          },
          {
            item: 'Coleslaw or Beans',
            size: 'Large',
          },
          {
            quantity: 4,
            item: 'Fries',
            size: 'Regular',
          },
          {
            quantity: 1,
            unit: 'bottle',
            item: 'drink',
          },
        ],
      },
      {
        price: 22,
        subItems: [
          {
            quantity: 12,
            item: 'Chicken',
            unit: 'piece',
          },
          {
            quantity: 8,
            item: 'Spicy Wings',
          },
          {
            item: 'Coleslaw or Beans',
            size: 'Large',
          },
          {
            quantity: 4,
            item: 'Fries',
            size: 'Regular',
          },
          {
            quantity: 1,
            unit: 'bottle',
            item: 'drink',
          },
        ],
      },
    ],
  },
  {
    name: 'Yummy Special',
    meals: [
      {
        price: 22,
        subItems: [
          {
            quantity: 10,
            item: 'Chicken',
            unit: 'piece',
          },
          {
            quantity: 8,
            item: 'Spicy Wings',
          },
          {
            item: 'Coleslaw or Beans',
            size: 'Large',
          },
          {
            quantity: 1,
            item: 'Salad',
            unit: 'box',
          },
          {
            quantity: 1,
            unit: 'bottle',
            item: 'drink',
          },
        ],
      },
      {
        price: 24,
        subItems: [
          {
            quantity: 12,
            item: 'Chicken',
            unit: 'piece',
          },
          {
            quantity: 8,
            item: 'Spicy Wings',
          },
          {
            item: 'Coleslaw or Beans',
            size: 'Large',
          },
          {
            quantity: 1,
            item: 'Salad',
            unit: 'box',
          },
          {
            quantity: 1,
            unit: 'bottle',
            item: 'drink',
          },
        ],
      },
    ],
  },
  {
    name: 'Family Round House Sampler',
    price: 23,
    subItems: [
      {
        quantity: 4,
        item: 'Chicken',
        unit: 'piece',
      },
      {
        quantity: 2,
        item: 'Chicken Fillet Burgers',
      },
      {
        quantity: 6,
        item: 'Spicy Wings',
      },
      {
        item: 'Lamb Ribs',
        quantity: 4,
      },
      {
        quantity: 4,
        item: 'Fries',
        size: 'Regular',
      },
      {
        quantity: 1,
        unit: 'bottle',
        item: 'drink',
      },
    ],
  },
];

export const kebabs: FoodItem[] = [
  {
    number: 1,
    name: 'Doner Kebab',
    description:
      'Fresh lamb specially seasoned & grilled on an upright spit, then carved',
    variants: [
      {
        variant: 'Medium',
        price: 5.5,
      },
      {
        variant: 'Large',
        price: 7,
      },
    ],
  },
  {
    number: 2,
    name: 'Lamb Shish',
    description:
      'Cubes of lamb specially marinated and cooked over charcoal grill',
    variants: [
      {
        variant: 'Medium',
        price: 6,
      },
      {
        variant: 'Large',
        price: 8,
      },
    ],
  },
  {
    number: 3,
    name: 'Chicken Shish',
    description: 'Cubes of tender chicken breast cooked over charcoal grill',
    variants: [
      {
        variant: 'Medium',
        price: 6,
      },
      {
        variant: 'Large',
        price: 8,
      },
    ],
  },
  {
    number: 4,
    name: 'Kofte Kebab',
    description:
      'Fresh minced lamb with parsley and oriental herbs cooked on charcoal grill',
    variants: [
      {
        variant: 'Medium',
        price: 6,
      },
      {
        variant: 'Large',
        price: 8,
      },
    ],
  },
  {
    number: 5,
    name: 'Mixed Kebab',
    description:
      'Lamb Shish, Kofte Shish & Doner Kebab. Salad & pitta served separately.',
    variants: [
      {
        price: 14,
      },
    ],
  },
  {
    number: 6,
    name: `Yummy's Special (Serves 2)`,
    description:
      'Lamb Doner, Lamb Shish, Kofte Shish & Chicken Shish. Salad, pitta & sauce served separately.',
    variants: [
      {
        price: 19,
      },
    ],
  },
  {
    number: 7,
    name: 'Doner Meat & Chips',
    description: 'Doner Meat & Chips in large container',
    variants: [
      {
        price: 7,
      },
    ],
  },
  {
    number: 8,
    name: 'Kebab Roll',
    description: 'Doner Meat in Burger Bun',
    variants: [{ price: 4 }, { price: 5, variant: 'With Chips' }],
  },
];

export const combinationKebabs: FoodItem[] = [
  {
    number: 10,
    name: 'Lamb Shish & Kofte Shish',
    variants: [
      {
        price: 8,
      },
    ],
  },
  {
    number: 11,
    name: 'Chicken Shish & Kofte Shish',
    variants: [
      {
        price: 8,
      },
    ],
  },
  {
    number: 12,
    name: 'Chicken Shish & Lamb Shish',
    variants: [
      {
        price: 8,
      },
    ],
  },
  {
    number: 13,
    name: 'Lamb Shish & Lamb Doner',
    variants: [
      {
        price: 8,
      },
    ],
  },
  {
    number: 14,
    name: 'Chicken Shish & Lamb Doner',
    variants: [
      {
        price: 8,
      },
    ],
  },
  {
    number: 15,
    name: 'Kofte Shish & Lamb Doner',
    variants: [
      {
        price: 8,
      },
    ],
  },
];

export const fish: FoodItem[] = [
  {
    name: 'Cod Fish',
    variants: [{ price: 4.5 }],
  },
  {
    name: 'Scampi',
    variants: [
      {
        variant: '8 pieces',
        price: 4,
      },
      {
        variant: '12 pieces',
        price: 5,
      },
    ],
  },
  {
    name: 'Scampi & Chips',
    variants: [
      {
        price: 5,
      },
    ],
  },
  {
    name: 'Fish & Chips',
    variants: [
      {
        price: 5,
      },
    ],
  },
];

export const startersAndExtras: FoodItem[] = [
  {
    name: 'Humus',
    variants: [
      {
        variant: 'in Pitta',
        price: 1.8,
      },
      {
        variant: 'with Salad', // TODO: does this come in pitta?
        price: 2.5,
      },
    ],
  },
  {
    name: 'Tarama',
    variants: [
      {
        variant: 'in Pitta',
        price: 1.8,
      },
      {
        variant: 'with Salad', // TODO: does this come in pitta?
        price: 2.5,
      },
    ],
  },
  {
    name: 'Cacik',
    variants: [
      {
        price: 1.8,
      },
    ],
  },
  {
    name: 'Coleslaw',
    variants: [
      {
        price: 1.5,
      },
    ],
  },
  {
    name: 'Beans',
    variants: [
      {
        price: 1.5,
      },
    ],
  },
  {
    name: 'Side Salad',
    variants: [
      {
        price: 1.5,
      },
    ],
  },
  {
    name: 'Pickled Chillies',
    variants: [
      {
        price: 1.8,
      },
    ],
  },
  {
    name: 'Sauces',
    description: 'Chilli Sauce, Garlic Sauce, Burger Sauce, Ketchup or Mayo',
    variants: [
      {
        price: 0.5,
      },
    ],
  },
  {
    name: 'Pitta',
    variants: [{ price: 0.3 }],
  },
  {
    name: 'Fries',
    variants: [
      {
        price: 1.5,
        variant: 'Regular', // TODO: with cheese?
      },
      {
        price: 1.8,
        variant: 'Medium',
      },
      {
        price: 2.5,
        variant: 'Large',
      },
    ],
  },
  {
    name: 'Fries with Cheese',
    variants: [
      {
        price: 2.5,
        variant: 'Medium',
      },
      {
        price: 3.5,
        variant: 'Large',
      },
    ],
  },
  {
    name: 'Salad in Pitta',
    variants: [
      {
        price: 1.9,
      },
    ],
  },
  {
    name: 'Chips in Pitta',
    variants: [
      {
        price: 2.8,
      },
      {
        price: 3.3,
        variant: 'with Salad',
      },
    ],
  },
  {
    name: 'Sausage',
    variants: [
      {
        price: 1.8,
      },
      {
        variant: 'with Chips',
        price: 3,
      },
    ],
  },
  {
    name: 'Onion Rings',
    variants: [
      {
        price: 2,
        variant: '8 pieces',
      },
      {
        price: 3,
        variant: '12 pieces',
      },
    ],
  },
  {
    name: 'Potato Wedges',
    variants: [
      {
        price: 2.3,
        variant: 'Small',
      },
      {
        price: 3.5,
        variant: 'Large',
      },
    ],
  },
  {
    name: 'Corn on the Cob',
    variants: [
      {
        price: 1.5,
      },
    ],
  },
  {
    name: 'Hash Brown',
    variants: [
      {
        price: 2,
        variant: '3 pieces',
      },
    ],
  },
  {
    name: 'Apple Pie',
    variants: [
      {
        price: 1.5,
      },
    ],
  },
  {
    name: 'Slice of Cheese',
    variants: [
      {
        price: 0.3,
      },
    ],
  },
];

export const burgersAndWraps: FoodItem[] = [
  {
    name: 'Chicken Fillet Burger',
    variants: [
      {
        price: 2.8,
      },
    ],
  },
  {
    name: 'Steak Burger',
    variants: [
      {
        price: 2.5,
      },
    ],
  },
  {
    name: 'Tower Burger',
    variants: [
      {
        price: 3.5,
      },
    ],
  },
  {
    name: 'Double Ringer Burger',
    variants: [
      {
        price: 3.5,
      },
    ],
  },
  {
    name: 'BBQ Chicken Burger',
    variants: [
      {
        price: 3,
      },
    ],
  },
  {
    name: 'Double Decker Beef Burger',
    variants: [
      {
        price: 3,
      },
    ],
  },
  {
    name: 'Cheese Burger',
    variants: [
      {
        price: 2.3,
      },
    ],
  },
  {
    name: '1/4 Pounder Burger',
    variants: [
      {
        price: 3.5,
      },
    ],
  },
  {
    name: '1/2 Pounder Burger',
    variants: [
      {
        price: 4.5,
      },
    ],
  },
  {
    name: 'Veggie Burger',
    variants: [
      {
        price: 2.5,
      },
    ],
  },
  {
    name: 'Fish Burger',
    variants: [
      {
        price: 2.8,
      },
    ],
  },
  {
    name: 'Chicken Wrap',
    variants: [
      {
        price: 3.5,
      },
    ],
  },
  {
    name: 'BBQ Chicken Wrap',
    variants: [
      {
        price: 3.8,
      },
    ],
  },
];

export const drinks: FoodItem[] = [
  {
    name: 'Water',
    description: '0.5l bottle',
    variants: [
      {
        price: 0.8,
      },
    ],
  },
  {
    name: 'Ribena',
    description: 'Blackcurrant or Strawberry',
    variants: [
      {
        price: 1,
      },
    ],
  },
  {
    name: 'Lucozade',
    description: 'Orange',
    variants: [
      {
        price: 1.5,
      },
    ],
  },
  {
    name: 'Cans',
    description: 'Coke, Diet Coke, Tango Orange, Pepsi, 7UP or Dr. Pepper',
    variants: [
      {
        price: 1,
      },
    ],
  },
  {
    name: 'Bottle',
    description: 'Coke, Diet Coke, Tange Orange, Pepsi or 7UP',
    variants: [
      {
        price: 3,
      },
    ],
  },
];

export const friedChicken: FoodItem[] = [
  {
    name: 'Fried Chicken',
    variants: [
      {
        price: 1.5,
        variant: '1 piece',
      },
      {
        price: 3,
        variant: '2 pieces',
      },
      {
        price: 4.5,
        variant: '3 pieces',
      },
    ],
  },
  {
    name: 'Fillet Strips',
    variants: [
      {
        price: 4.5,
        variant: '4 pieces',
      },
      {
        price: 6,
        variant: '6 pieces',
      },
    ],
  },
  {
    name: 'BBQ Chicken',
    variants: [
      {
        price: 1.7,
        variant: '1 piece',
      },
      {
        price: 3.3,
        variant: '2 pieces',
      },
      {
        price: 4.5,
        variant: '3 pieces',
      },
    ],
  },
  {
    name: 'Spicy Wings',
    variants: [
      {
        price: 1.3,
        variant: '3 pieces',
      },
      {
        price: 2.5,
        variant: '6 pieces',
      },
      {
        price: 3.5,
        variant: '10 pieces',
      },
    ],
  },
  {
    name: 'BBQ Lamb Ribs',
    variants: [
      {
        price: 5,
        variant: '4 pieces',
      },
      {
        price: 6.5,
        variant: '6 pieces',
      },
    ],
  },
  {
    name: 'Nuggets',
    variants: [
      {
        price: 1.5,
        variant: '4 pieces',
      },
      {
        price: 2.5,
        variant: '6 pieces',
      },
      {
        price: 4,
        variant: '10 pieces',
      },
    ],
  },
  {
    name: 'Poppers',
    variants: [
      {
        price: 3,
        variant: 'Small (12 pieces)',
      },
      {
        price: 4,
        variant: 'Large (20 pieces)',
      },
    ],
  },
];
