import { default as pluralise } from 'pluralize';
import { FoodItem, isMeal, MealItem, MenuItem, MenuItems } from 'types';

const descriptionTransformer = (mealItems: MealItem[]) => {
  const array: string[] = [];

  mealItems.forEach((mealItem) => {
    if (mealItem.unit) {
      array.push(
        `${mealItem.quantity} ${pluralise(
          mealItem.unit,
          mealItem.quantity
        )} of ${mealItem.item}`
      );
    } else {
      array.push(
        `${mealItem.quantity || ''} ${mealItem.size || ''} ${
          mealItem.item
        }`.trim()
      );
    }
  });

  return array.join(', ');
};

const alphabet = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

export const mealTransformer = (menuItems: MenuItems): MenuItem[] => {
  const items: MenuItem[] = [];

  menuItems.forEach((menuItem) => {
    if (isMeal(menuItem)) {
      const number = menuItem.number ? `#${menuItem.number} ` : '';
      items.push({
        name: `${number}${menuItem.name!}`,
        description: descriptionTransformer(menuItem.subItems),
        price: `£${menuItem.price.toFixed(2)}`,
      });
    } else {
      menuItem.meals.forEach((subMeal, i) => {
        const name = menuItem.number ? `#${menuItem.number}${alphabet[i]} ${menuItem.name}` : `${menuItem.name} (${alphabet[i]})`;
        items.push({
          name,
          description: descriptionTransformer(subMeal.subItems),
          price: `£${subMeal.price.toFixed(2)}`,
        });
      });
    }
  });

  return items;
};

export const itemTransformer = (items: FoodItem[]): MenuItem[] => {
  const result: MenuItem[] = [];

  items.forEach((item) => {
    result.push({
      name: item.name,
      description: item.description || '',
      price: item.variants.map((variant) => ({
        variant: variant.variant || '',
        price: `£${variant.price.toFixed(2)}`,
      })),
    });

    //  `£${variant.price.toFixed(2)}`

    // item.variants.forEach(variant => {
    //   result.push()
    // });
  });

  return result;
};
